<template>
    <v-container fluid>
        <!-- <Breadcrumbs /> -->
        <!-- Knowlarity Creds Input -->
        <v-card class="rounded d-flex flex-row flex-lg-wrap">
            <v-row class="ma-0">
                <v-col>
                    <v-card color="transparent" elevation="0" style="padding: 10px; text-align: center !important">
                        <v-card-text style="margin-bottom: 25px">
                            <!-- <h2 style="margin-bottom: 30px">
                        Lorem ipsum
                      </h2> -->
                            <div align="center" style="margin-bottom: 10px">
                                <h1 style="margin-bottom: 40px">Godial + Woocommerce</h1>
                            </div>
                            <div style="padding: 5px 15px; text-align: center">
                                <!-- <h5
                          style="
                            line-height: 17px;
                            font-weight: 500;
                            font-size: 14px;
                          "
                        >
                          How to use?
                        </h5> -->

                                <div style="text-align: left">
                                    <p style="
                              margin-top: 20px;
                              font-size: 18px;
                              font-weight: 600;
                              margin-bottom: 10px;
                            ">
                                        How to integrate Woocommerce with godial?
                                    </p>
                                    <p>
                                        1. Create a list in Godial. Goto CRM > New list
                                        <br />
                                        2. Download the integration file from this link: <a
                                            href="https://firebasestorage.googleapis.com/v0/b/godial-enterprise-export/o/godialwpplugin%202.zip?alt=media"
                                            target="_blank">Download</a>
                                        <br />
                                        3. Now go to your wordpress website > Plugin > Add new Plugin > Upload Plugin
                                        and put the file in there and click on Install Now. After intallation activate
                                        the plugin.
                                        <br />
                                        4. Now go to Settings > Godial CRM WP Integration.
                                        <br />
                                        5. Copy the Godial API token from External API Section and paste it in the
                                        Access Token part and click on save.
                                        <br />
                                        6. Now wait a bit and Click on Get List. Then wait you will see the lists linked
                                        to your company and Choose the list you want to connect and then check the
                                        Enable Godial Sync. Your leads are ready to sync with godial on every new order
                                        creation.
                                        <br />
                                        <br /> We provided a detailed video about the setup. You can check that out as
                                        well.
                                    </p>
                                </div>
                                <!-- <p class="text-left">
                          For more details visit :
                          <a
                            target="_blank"
                            href="https://help.indiamart.com/knowledge-base/lms-crm-integration/"
                          >
                            this link</a
                          >
                        </p> -->
                            </div>
                        </v-card-text>
                        <v-footer color="transparent" absolute>
                            <v-flex style="text-align: center !important">
                                <p style="
                            font-size: 12px;
                            opacity: 0.9;
                            margin-bottom: 0px;
                          ">
                                    Feel free to reach out to us at
                                    <a href="mailto:support@godial.cc">support@godial.cc</a>
                                    if you are interested in these features
                                </p>
                            </v-flex>
                        </v-footer>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card flat>
                        <v-flex xs12 sm10 offset-sm1 md10 offset-md1>
                            <v-layout row wrap>
                                <!-- <v-flex d-flex justify-center class="mt-10" style="padding-top: 22px">Enable Pabbly
                                </v-flex> -->
                                <!-- <v-flex class="mt-10" d-flex justify-center>
                                    <v-switch v-model="pabbly.enabled" color="primary"></v-switch>
                                </v-flex> -->
                            </v-layout>
                        </v-flex>
                    </v-card>
                    <v-card class="d-flex align-center" color="transparent" elevation="0"
                        style="margin-top:25px; padding: 15px 0px; text-align: center; height: 100%;">
                        <div class="video-container">
                            <iframe class="tutorial" src="https://www.youtube.com/embed/VRWr3RuC9e4?si=KSkCsQjppZyzXO09"
                                title="YouTube video player" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen></iframe>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>

        <!-- Loader -->
        <v-dialog v-model="loader" persistent width="300">
            <v-card color="primary white--text" dark>
                <v-card-text style="padding: 18px 25px">
                    <h3 style="margin-bottom: 5px; font-weight: 400; font-size: 16px">
                        Please Wait...
                    </h3>
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import fb from "../../js/firebase";

export default {
    data() {
        return {
            pabbly: {
                type: "pabbly",
                config: {
                    // email: "",
                    userId: "",
                    profileId: "",
                    inquiry_key: "",
                    inquiry_listId: "",
                    buyleads_key: "",
                    buyleads_listId: "",
                },
                listId: "",
                companyId: "",
                enabled: false,
            },
            loader: false,
            lists: [],
        };
    },

    computed: {
        ...mapGetters(["ENDPOINT", "companyDetails", "selectedTeam"]),
    },
    watch: {},
    created() {
        this.fetchLists();
        this.getTradeIndiaDetails();
        this.pabbly.companyId = this.companyDetails.id;

        console.log(this.companyDetails.id);
    },
    methods: {
        async getTradeIndiaDetails() {
            var resp = await this.$http.get(
                `${this.ENDPOINT}/integrations?filter[where][companyId]=${this.companyDetails.id}&filter[where][type]=tradeindia`
            );

            console.log("trade india data", resp.body);

            if (resp.body.length > 0) this.pabbly = resp.body[0];
        },
        getTeamIds() {
            var teams = [];
            if (this.selectedTeam == "allTeams") {
                teams = _.map(this.teams, "id");
            } else {
                teams.push(this.selectedTeam);
            }

            teams = _.without(teams, "allTeams");

            return teams;
        },
        async fetchLists() {
            try {
                this.lists = [];
                var response = [];

                var teamIds = this.getTeamIds();

                // Teams sections
                response = await this.$http.post(`${this.ENDPOINT}/lists/fetch`, {
                    teams: teamIds,
                });
                this.lists = response.body;

                if (this.lists.length < 1) {
                    this.loader = false;
                    return;
                }
            } catch (e) {
                this.loader = false;
                console.log("error in list fetch", e);
            }
        },

        async setTradeIndia() {
            this.loader = true;
            try {
                this.pabbly.companyId = this.companyDetails.id;
                this.pabbly.type = "tradeindia";
                this.pabbly.config.email = `ti-${this.companyDetails.id}@mg.godial.cc`;
                var res = {};
                //If new user
                if (typeof this.pabbly.id == "undefined") {
                    // Add Indiamart Details In Company Data
                    res = await this.$http.post(
                        `${this.ENDPOINT}/integrations`,
                        this.pabbly
                    );
                } else {
                    var data = JSON.parse(JSON.stringify(this.pabbly));
                    delete data.id;
                    res = await this.$http.put(
                        `${this.ENDPOINT}/integrations/${this.pabbly.id}`,
                        data
                    );
                }
                // Success Message
                if (res.status == 200) {
                    // Set Company Object for Store dispatch
                    this.getTradeIndiaDetails();
                    this.loader = false;
                    try {
                        fb.log("tradeIndia_added");
                    } catch (e) {
                        console.log("Error in FB log", e);
                    }
                    return this.$swal({
                        type: "success",
                        text: "TradeIndia Added to Godial Successfully",
                    });
                } else {
                    return this.$swal({
                        type: "warning",
                        text: "Something Went Wrong",
                    });
                }
            } catch (err) {
                console.log(err);
                this.loader = false;
                return this.$swal({
                    type: "warning",
                    text: err.body.error.message,
                });
            }
        },
    },
};
</script>

<style>
.tutorial {
    width: 100%;
    min-height: 40vh;
    height: auto;
    aspect-ratio: 16/9;
}

.video-container {
    min-width: 20rem;
    width: 100%;
    max-height: 100%;
    display: flex;
    align-items: center;
}
</style>